import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  // azure.getRepositories().then(res=>console.log(res))

  
  return (
    <><h1>welcome</h1><p>I'm still walking on a lonely road full of people, but I am happy </p><p>is#xuelin.me</p></>
  );
}

export default App;
